import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Platform, ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { NgForm } from '@angular/forms';
import { ThemeInfoService } from '../../services/theme-info.service';
import { ThemeInfo } from '../../models/themeInfo';
import { UserService, QuproServiceError, LoginData, QuproServiceErrorType } from '@arpada/arp-lib-common-qupro';
import { UiService } from '../../../app/services/ui.service';
import { environment } from '../../../environments/environment';
import { MessagesService } from '../../services/messages.service';
import { PortalPropietarioService } from '../../services/portal-propietario.service';
import { CambioPasswordComponent } from '../../components/cambio-password/cambio-password.component';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LanguagesInfo } from 'src/assets/i18n/languages-info';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  private static LAST_USERNAME_STORAGE_KEY = 'lastUsername';
  private static TOAST_DURATION = 5000;

  loginUser = {
    email: '',
    password: ''
  };

  mostrarLogin = false;
  version = environment.version;

  private themeInfo: ThemeInfo = ThemeInfoService.DEFAULT_THEME_INFO;
  public logoUrl: string = ThemeInfoService.DEFAULT_THEME_INFO.logoUrl;
  public isLoginLoading = false;
  public isUnauthorized = false;
  captcha = false;
  acceptButton = false;
  showLeyenda = false;
  resetPasswordUrl: string;
  showDownloadAnchor = false;
  hrefDownloadAnchor = '';
  private lastTimeClickVersion = null;
  private clicksNumberOnVersion = 0;
  private readonly sendReportErrorEmailContact: string = environment.contactAddress;

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(Router) private router: Router,
              @Inject(HttpClient) private httpClient: HttpClient,
              @Inject(UserService) private userService: UserService,
              @Inject(Platform) public platform: Platform,
              @Inject(UiService) private uiService: UiService,
              private messagesService: MessagesService,
              private portalPropietarioService: PortalPropietarioService,
              private modalCtrl: ModalController,
              private device: DeviceDetectorService,
              ) {
      let resetPasswordUrlBase: string;
      if (this.isWeb() && (location && location.origin)) {
        resetPasswordUrlBase = location.origin;
      } else {
        resetPasswordUrlBase = environment.resetPasswordUrl;
      }
      if (resetPasswordUrlBase && !resetPasswordUrlBase.endsWith('/')) {
        resetPasswordUrlBase += '/';
      }
      this.resetPasswordUrl = resetPasswordUrlBase + 'login';
  }

  async ngOnInit() {
    const lastUsername: string = localStorage.getItem(LoginPage.LAST_USERNAME_STORAGE_KEY);
    if (lastUsername) {
      this.loginUser.email = lastUsername;
    }
    const oldThemeInfo: ThemeInfo = ThemeInfoService.load();
    if (this.isWeb()) {
      this.themeInfo = await ThemeInfoService.selectThemeFromURL(this.httpClient);
    } else if (oldThemeInfo) {
      this.themeInfo = oldThemeInfo;
    }
    this.assignThemeInfo(this.themeInfo);
    this.mostrarLogin = true;
    LanguagesInfo.loadLanguageByUser(this.loginUser.email);
  }

  private assignThemeInfo(themeInfoToAssign: ThemeInfo, now: boolean = true) {
    if (themeInfoToAssign) {
      if (now) {
        this.logoUrl = themeInfoToAssign.logoUrl;
        this.document.documentElement.style.cssText = themeInfoToAssign.cssText;
      }
      ThemeInfoService.save(themeInfoToAssign);
    }
  }

  onChangeEmailField() {
    LanguagesInfo.loadLanguageByUser(this.loginUser.email);
  }

  clickOnVersion() {
    if (!environment.production) {
      if (!this.lastTimeClickVersion) {
        this.lastTimeClickVersion = new Date().getTime();
      }
      if ((new Date().getTime() - this.lastTimeClickVersion) <= 7 * 1000) {
        this.clicksNumberOnVersion++;
      } else {
        this.clicksNumberOnVersion = 0;
        this.lastTimeClickVersion = new Date().getTime();
      }
      if (this.clicksNumberOnVersion >= 7) {
        this.clicksNumberOnVersion = 0;
        this.lastTimeClickVersion = null;
        const contenidoObj = {
          attachments: {
            localStorage,
            loggerString: this.portalPropietarioService.logger.getLoggerString(),
            device: this.device,
            extraInfo: 'width: ' + this.platform.width() + ', height: ' +
                        this.platform.height() + '; isLandscape?: ' + this.platform.isLandscape() +
                        '; isPortrait?: ' + this.platform.isPortrait() +
                        '; url: ' + this.platform.url()
                        ,
          },
        };
        if (this.platform.is('cordova')) {
          const mailBody: string = JSON.stringify(contenidoObj);
          this.hrefDownloadAnchor = 'mailto:' + this.sendReportErrorEmailContact + '?subject=' +
                                    this.messagesService.getLoginReportEmailSubject() + '&body=' + mailBody;
        } else {
          let downloadContent = 'data:text/plain;base64,';
          downloadContent = downloadContent + btoa(JSON.stringify(contenidoObj));
          this.hrefDownloadAnchor = downloadContent;
        }
        this.showDownloadAnchor = true;
      }
    }
  }

  async login( fLogin: NgForm ) {
    this.portalPropietarioService.cerrarSesion();
    if ( fLogin.invalid ) { return; }
    if ( this.isLoginLoading ) { return; } // Not multiple submit
    this.isLoginLoading = true;
    const loginUser: string = this.loginUser.email;
    const password: string = this.loginUser.password;
    this.userService.loginUsuario(loginUser, password).subscribe(
      async (resp: LoginData) => {
        LanguagesInfo.saveCurrentLanguageToUser(loginUser);
        if (resp.password_reset) {
          this.changePassword();
        }
        localStorage.setItem(LoginPage.LAST_USERNAME_STORAGE_KEY, loginUser);
        if (!this.isWeb()) {
          if (resp.theme_name) {
            this.themeInfo = await ThemeInfoService.selectThemeFromParam(this.httpClient, resp.theme_name);
            this.assignThemeInfo(this.themeInfo, false);
          } else {
            this.themeInfo = ThemeInfoService.DEFAULT_THEME_INFO;
            this.assignThemeInfo(this.themeInfo, false);
          }
        }
        this.showLeyenda = false;
        this.router.navigate(['/']).finally(this.onFinishNavigateAfterLogin);
      },
      (error: QuproServiceError) => {
        if (error) {
          switch (error.type) {
            case QuproServiceErrorType.UNATHORIZED:
              this.isUnauthorized = true;
              break;
            case QuproServiceErrorType.QUPRO_ERROR:
              this.portalPropietarioService.logger.error('[LoginPage.login] (quproError).', error);
              this.uiService.showSimpleMiddleToast(error.message, LoginPage.TOAST_DURATION);
              break;
            case QuproServiceErrorType.CONNECTION_PROBLEM:
              this.portalPropietarioService.logger.error('[LoginPage.login] ConnectionProblem.', error);
              this.uiService.showSimpleMiddleToast(this.messagesService.getConnectionProblemMessage(), LoginPage.TOAST_DURATION);
              break;
            default:
              this.portalPropietarioService.logger.error('[LoginPage.login] UnrecognizedError.', error);
              this.uiService.showSimpleMiddleToast(this.messagesService.getUnexpectedErrorMessageWithEmailContact(),
                                                   LoginPage.TOAST_DURATION);
              break;
          }
        }
        this.isLoginLoading = false;
      });
  }

  onFinishNavigateAfterLogin = () => {
    this.resetLogin();
  }

  private resetLogin() {
    this.isLoginLoading = false;
    this.isUnauthorized = false;
    if (!this.isWeb()) {
      this.logoUrl = this.themeInfo.logoUrl;
      this.document.documentElement.style.cssText = this.themeInfo.cssText;
    }
  }

  isWeb(): boolean {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  async changePassword() {
    const modal = await this.modalCtrl.create({
      component: CambioPasswordComponent,
      backdropDismiss: false,
      componentProps: {
        canCancel: false
      }
    });
    await modal.present();
    if (await modal.onDidDismiss()) {
      const {data} = await modal.onDidDismiss();
      // console.log(data);
      this.router.navigate(['/login']);
    }
  }

  async showResetPassword() {
    this.showLeyenda = false;
    const emailResetPassword = this.loginUser.email;
    const modal = await this.modalCtrl.create({
      component: ResetPasswordComponent,
      backdropDismiss: false,
      componentProps: {
        canCancel: false,
        resetPasswordData: {
          email: emailResetPassword,
          didResetPasswordCallback: (didResetPassword: boolean) => {
            if (didResetPassword) {
              this.showLeyenda = true;
            }
          },
        },
      }
    });
    await modal.present();
  }

}
