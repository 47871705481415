// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://dev-qupro.myvivienda.com:8078/api',
  odooURL: 'https://dev-qupro.myvivienda.com:8077/',
  apiToken: '5FCB9A171352774B9155E8D84F979',
  apiMaxSessionTime: 1000 * 60 * 60 * 23, // 1 day minus 1 hour
  contactAddress: 'desarrollo@grupoarpada.com',
  apiMaxCachedTime: 1000 * 60 * 60 * 10, // 10 hours
  // apiMaxSessionTime: 1000*30, // 30 seconds only for testing proposals
  baseUrl: '/',
  version: '0.0.314', // no tocar para que funcione el autoescalado de version en CI/CD
  resetPasswordUrl: 'https://test-www.myvivienda.com',
  recaptchaApiKey: '6Lfz270UAAAAABX8bc8-c2edPRsten19OWDkuBxT',
  recaptchaApiKeyAlias: 'owner',
  appKeyName: 'portal-owner',
  timeToAccumulateErrors: 500,
  // fastapiExposedQuproQueriesBaseURL: 'http://127.0.0.1:8886/queries/administrative-queries',
  // fastapiExposedQuproCommandsBaseURL: 'http://127.0.0.1:8886/commands/administrative-queries',
  fastapiExposedQuproQueriesBaseURL: 'https://dev-ibejarano-fastapi-exposed-qupro-service.dev-aks.grupoarpada.com/queries/administrative-queries',
  fastapiExposedQuproCommandsBaseURL: 'https://dev-ibejarano-fastapi-exposed-qupro-service.dev-aks.grupoarpada.com/commands/administrative-queries'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
